import { createContext, useContext } from 'react';

interface ModalContext {
  modalType: string;
  isModalOpened: boolean;
  state: ModalState;
  openModal: (type: string, callBack?: any) => void;
  openModalWithData: (type: string, data: any) => void;
  closeModal: () => void;
  changeModalContent?: (type: string, data: any) => void;
}

export interface ModalState {
  data: any;
  currentModal: string;
  isFavorite: boolean;
  isOpenGame: boolean;
  isLogOut: boolean;
}

export const initModalState: ModalState = {
  isFavorite: false,
  isOpenGame: false,
  isLogOut: false,
  currentModal: '',
  data: null,
};

export const ModalContext = createContext<ModalContext>({
  modalType: '',
  isModalOpened: false,
  state: initModalState,
  /* eslint-disable @typescript-eslint/no-empty-function */
  openModal: () => {},
  openModalWithData: () => {},
  closeModal: () => {},
  changeModalContent: () => {},
  /* eslint-enable @typescript-eslint/no-empty-function */
});

ModalContext.displayName = 'ModalContext';

export const useModalContext = () => {
  const ctx = useContext(ModalContext);

  if (ctx === undefined) {
    throw new Error('useModalContext must be used within a ModalProvider');
  }

  return ctx;
};
