import { createContext, useContext } from 'react';
import { User } from 'types/user';

interface AuthContext {
  user: User | null;
  setUser: (user: User | null) => void;
  updateUser: (user: User | null) => void;
  hasAccessToken: boolean;
  setHasAccessToken: (has: boolean) => void;
}

export const AuthContext = createContext<AuthContext>({
  user: null,
  hasAccessToken: false,
  /* eslint-disable @typescript-eslint/no-empty-function */
  setUser: () => {},
  updateUser: () => {},
  setHasAccessToken: () => {},
  /* eslint-enable @typescript-eslint/no-empty-function */
});

AuthContext.displayName = 'AuthContext';

export const useAuthContext = () => {
  const ctx = useContext(AuthContext);

  if (ctx === undefined) {
    throw new Error('useAuthContext must be used within a AuthProvider');
  }

  return ctx;
};
