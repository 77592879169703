import { useReducer, useState } from 'react';
import { ModalContext, ModalState, initModalState } from './modalContext';
import { FIELD_NAME } from 'types/login';
import { FIELD_NAME_GAME } from 'types/game';
import { MODAL_TYPES } from 'types/modals';

export const modalReducer = (state: ModalState, action: any) => {
  switch (action.type) {
    case FIELD_NAME.LOGIN: {
      return {
        ...initModalState,
        currentModal: FIELD_NAME.LOGIN,
        data: action.payload,
      };
    }
    case FIELD_NAME.LOGIN_FOR_FAVORITE: {
      return {
        ...initModalState,
        isFavorite: true,
        currentModal: FIELD_NAME.LOGIN,
        data: action.payload,
      };
    }
    case FIELD_NAME.LOGIN_FOR_PLAY_GAME: {
      return {
        ...initModalState,
        isOpenGame: true,
        currentModal: FIELD_NAME.LOGIN,
        data: action.payload,
      };
    }
    case FIELD_NAME_GAME.GAME_SESSION_NEED_OPEN_GAME: {
      return {
        ...initModalState,
        isOpenGame: true,
        currentModal: FIELD_NAME_GAME.GAME_SESSION,
        data: action.payload,
      };
    }
    case FIELD_NAME_GAME.GAME_SESSION: {
      return {
        ...initModalState,
        currentModal: FIELD_NAME_GAME.GAME_SESSION,
        data: action.payload,
      };
    }

    case FIELD_NAME.SIGN_UP: {
      return {
        ...initModalState,
        currentModal: FIELD_NAME.SIGN_UP,
      };
    }
    case FIELD_NAME.LOGOUT: {
      return {
        ...initModalState,
        isLogOut: true,
        currentModal: FIELD_NAME.LOGOUT,
        data: action.payload,
      };
    }

    case MODAL_TYPES.CHOOSE_BALANCE: {
      return {
        ...initModalState,
        isLogOut: true,
        currentModal: MODAL_TYPES.CHOOSE_BALANCE,
        data: action.payload,
      };
    }
    case MODAL_TYPES.PROFILE: {
      return {
        ...initModalState,
        currentModal: MODAL_TYPES.PROFILE,
        data: action.payload,
      };
    }
    case MODAL_TYPES.DEPOSIT: {
      return {
        ...initModalState,
        currentModal: MODAL_TYPES.DEPOSIT,
        data: action.payload,
      };
    }
    case MODAL_TYPES.LEVELS: {
      return {
        ...initModalState,
        currentModal: MODAL_TYPES.LEVELS,
        data: action.payload,
      };
    }
    case MODAL_TYPES.NOTIFICATION: {
      return {
        ...initModalState,
        currentModal: MODAL_TYPES.NOTIFICATION,
        data: action.payload,
      };
    }

    case MODAL_TYPES.HISTORY: {
      return {
        ...initModalState,
        currentModal: MODAL_TYPES.HISTORY,
        data: action.payload,
      };
    }

    default: {
      return { ...initModalState };
    }
  }
};

export const ModalProvider = ({ children }: any) => {
  const [modalType, setModalType] = useState<string>('');

  const [state, dispatch] = useReducer(modalReducer, initModalState);
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);

  const openModal = (type: string) => {
    setIsModalOpened(true);
    setModalType(type);
  };
  const closeModal = () => {
    setIsModalOpened(false);
    dispatch({ type: '' });
  };

  const changeModalContent = (type: string, data: any) => {
    setModalType(type);
    dispatch({ type, payload: data });
  };

  const openModalWithData = (type: string, data: any) => {
    setIsModalOpened(true);
    setModalType(type);
    dispatch({ type, payload: data });
  };

  return (
    <ModalContext.Provider
      value={{
        state,
        modalType,
        isModalOpened,
        openModal,
        closeModal,
        openModalWithData,
        changeModalContent,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
