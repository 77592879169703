import { Box} from '@mui/material'
import styles from './constractionUnder.module.scss';

function UnderConstraction() {
  return (
    <Box className={styles.main}>Ведутся технические работы вернёмся 14 числа в 12:00</Box>
  );
}

export default UnderConstraction;
