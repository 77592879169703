export interface IFormLoginData {
  login: string;
  password: string;
  [key: string]: string;
}

export interface IErrors {
  [key: string]: string;
}

export enum FIELD_NAME {
  LOGIN = 'login',
  LOGOUT = 'logout',
  LOGIN_FOR_FAVORITE='loginForFavorites',
  LOGIN_FOR_PLAY_GAME = 'loginForPlayGame',
  PASSWORD = 'password',
  SIGN_UP='signup',
  PHONE='phone'
}
