import { actionTypes } from 'redux/constants/actionTypes';

const initial = {};

export const siteConfigsReducer = (state = initial, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_CONFIGS:
      return { ...state, ...payload };
    default:
      return state;
  }
};
