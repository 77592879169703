import { actionTypes } from 'redux/constants/actionTypes';

const initial = {};

export const userReducer = (state = initial, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_USER_DATA:
      return  payload !== null ? { ...payload } : null;
    case actionTypes.UPDATE_USER_DATA_FOR_FIELDS:
      return { ...state, ...payload }
    case actionTypes.UPDATE_USER_BALANCE:
      return { ...state, balance: payload }
    case actionTypes.UPDATE_USER_LEVEL:
      return { ...state, levelGroup: payload }
    case actionTypes.USER_GET_NOTIFICATION:
      return { ...state, notifications: { count: `${+(state?.notifications?.count ?? 0) + 1}`, lastNotifications: [payload, ...state.notifications.lastNotifications] } }
    case actionTypes.UPDATE_USER_NOTIFICATIONS:
      return { ...state, ...payload }
    case actionTypes.USER_NOTIFICATIONS_SEEN:
      return { ...state, notifications: { count: `${state?.notifications?.count - payload.length}`, lastNotifications: state.notifications.lastNotifications.filter(elm => !payload.includes(elm.id)) } }
    case actionTypes.GET_ALL_NOTIFICATIONS:
      return { ...state, allNotifications: payload }
    default:
      return state;
  }
};
