import { useState } from 'react';
import { AuthContext } from './authContext';
import { User } from 'types/user';
import { useStorage } from 'hooks/authHooks/useStorage';

export const AuthProvider = ({ children }: any) => {
  const { readUserData } = useStorage();
  const [user, setUserData] = useState<User | null>(readUserData());
  const [hasAccessToken, setHasToken] = useState<boolean>(false);

  const setUser = (user: User | null) => {
    setUserData(user);
  };

  const updateUser = (data: any) => {
    setUserData({ ...user, ...data });
  };

  const setHasAccessToken = (has: boolean) => {
    setHasToken(has);
  };

  return (
    <AuthContext.Provider value={{ user, setUser, hasAccessToken, setHasAccessToken, updateUser }}>
      {children}
    </AuthContext.Provider>
  );
};
