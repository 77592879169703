import { actionTypes } from 'redux/constants/actionTypes';

const initial = {
  siteCurrencies: [],
};

export const currencyReducer = (state = initial, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_CURRENCY_DATA:
      return { ...state, siteCurrencies: payload  };
    default:
      return state;
  }
};
