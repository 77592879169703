export const routing = {
  home: '/',
  slots: '/slots',
  notFound: '*',
  terms: '/terms',
  category: '/category',
  game: '/category/game',
  gameDemo: '/category/gameDemo',
  bonuses: '/bonuses',
  aboutUs: '/aboutUs',
  bonusTerms: '/bonusTerms',
  faq:'faq',
  live: '/live',
  wheel:'/wheel',
  deposit: '/deposit',
  provider: '/provider',
  statistics: '/statistics',
  payment:'/payment',
  paymentFailed:'/paymentfailed',
  paymentSuccess:'/paymentsuccess',
  paymentProcess:'/payment',
  process:'process'
};

// export const sensetiveRouts = [routing.casino];x`
