import { routing } from 'constants/routing';
import { useNavigate } from 'react-router-dom';
import { User } from 'types/user';
import { STORAGE_KEYS, localStorageService } from 'utils/storageService';

const storageDataObj = {
  accessToken: STORAGE_KEYS.ACCESS_TOKEN,
  refreshToken: STORAGE_KEYS.REFRESH_TOKEN,
  siteLanguage: STORAGE_KEYS.SITE_LANGUAGE,
  user: STORAGE_KEYS.USER,
  activeMenuTab: STORAGE_KEYS.ACTIVE_MENU_TAB,
} as { [x: string]: string };

export const useStorage = () => {
  const navigate = useNavigate();

  const logout = () => {
    // localStorageService.remove(STORAGE_KEYS.ACCESS_TOKEN);
    // localStorageService.remove(STORAGE_KEYS.REFRESH_TOKEN);
    localStorageService.put(STORAGE_KEYS.USER, null);
    return navigate(routing.home);
  };

  const userToken = () => {
    const user = localStorageService.read(STORAGE_KEYS.USER);
    return user?.accessToken;
  };

  const userRefresh = () => {
    const user = localStorageService.read(STORAGE_KEYS.USER);
    return user?.refreshToken;
  };

  const setStorageData = (data: { [x: string]: any }) => {
    for (const el in data) {
      const key = storageDataObj[el];
      if(key === undefined){
      localStorageService.put(el as STORAGE_KEYS, data[el]);
      }
      else {
      localStorageService.put(key as STORAGE_KEYS, data[el]);
      }
    }
  };

  const setUserTokens = (data: { refreshToken: string; accessToken: string }) => {
    const user = localStorageService.read(STORAGE_KEYS.USER);
    if (user) {
      localStorageService.put(STORAGE_KEYS.USER, {
        ...user,
        refreshToken: data.refreshToken,
        accessToken: data.accessToken,
      });
    }
  };

  const setUserData = (data: User | null) => {
    localStorageService.put(STORAGE_KEYS.USER, data);
  };

  const setUserBalanceType = (data: string) => {
    localStorageService.put(STORAGE_KEYS.BALANCE_TYPE, data);
  };

  const updateData = (data: any) => {
    const user = localStorageService.read(STORAGE_KEYS.USER);

    if (user) {
      const updatedUser = { ...user, user: { ...user.user, ...data } };
      localStorageService.put(STORAGE_KEYS.USER, updatedUser);
    }
  };

  const readUserData = () => {
    return localStorageService.read(STORAGE_KEYS.USER);
  };

  const readStorageData = (name: string) => {
    return localStorageService.read(name as STORAGE_KEYS);
  };

  return {
    logout,
    userToken,
    updateData,
    userRefresh,
    readUserData,
    setStorageData,
    setUserData,
    readStorageData,
    setUserBalanceType,
    setUserTokens,
  };
};
