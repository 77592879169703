import { io, Socket } from 'socket.io-client';
import { ClientToServerEvents, ServerToClientEvents } from 'types/socket/socket';
import { wsURL } from 'ws-connection';
import './App.scss';
import UnderConstraction from 'components/countDown/constractionUnder';

export const socket: Socket<ServerToClientEvents, ClientToServerEvents> = io(wsURL as string);

function App() {
  return <UnderConstraction />;
}

export default App;
