import { actionTypes } from 'redux/constants/actionTypes';

const initialList = []

export const topWinnersReducer = (state = initialList, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_TOP_WINNERS:
      return [...payload];
    default:
      return state;
  }
};
