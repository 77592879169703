import { actionTypes } from 'redux/constants/actionTypes';

const initial = {
  device: '',
};

export const deviceTypeReducer = (state = initial, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_DEVICE_TYPE:
      return { ...state, device: payload  };
    default:
      return state;
  }
};
