import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import store from './redux/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ModalProvider } from 'context/modal/modalProvider';
import { AuthProvider } from 'context/auth/authProvider';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './i18n';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <AuthProvider>
        <DndProvider backend={HTML5Backend}>
          <ModalProvider>
            <App />
          </ModalProvider>
        </DndProvider>
      </AuthProvider>
    </BrowserRouter>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
