import { actionTypes } from 'redux/constants/actionTypes';

const initial = {
  categories: [],
};

export const categoriesReducer = (state = initial, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_CATEGORIES:
      return { ...state, categories: payload  };
    default:
      return state;
  }
};
