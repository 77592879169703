
import { actionTypes } from 'redux/constants/actionTypes';

const initial = []
const initialWithoutGames = []

export const providersReducer = (state = initial, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_PROVIDERS:
      return [...payload];
    default:
      return state;
  }
};

export const providersReducerWithoutGames = (state = initialWithoutGames, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_PROVIDERS_WITHOUT_GAMES:
      return [...payload];
    default:
      return state;
  }
};