import { User } from 'types/user';

export enum STORAGE_KEYS {
  ACCESS_TOKEN = 'accessToken',
  REFRESH_TOKEN = 'refreshToken',
  MODE = 'mode',
  SITE_LANGUAGE = 'siteLanguage',
  USER = 'user',
  BALANCE_TYPE = 'balanceType',
  ACTIVE_MENU_TAB = 'activeMenuTab',
}

type StorageData = {
  [STORAGE_KEYS.ACCESS_TOKEN]: string;
  [STORAGE_KEYS.REFRESH_TOKEN]: string;
  [STORAGE_KEYS.MODE]: string;
  [STORAGE_KEYS.SITE_LANGUAGE]: string;
  [STORAGE_KEYS.BALANCE_TYPE]: string;
  [STORAGE_KEYS.ACTIVE_MENU_TAB]: string;
  [STORAGE_KEYS.USER]: User | null;
};

export class StorageService {
  private storage: Storage;

  constructor(storage: Storage) {
    this.storage = storage;
  }

  read<T extends STORAGE_KEYS>(key: T): StorageData[T] | null {
    const data = this.storage.getItem(key);
    return data ? JSON.parse(data) : null;
  }

  put<T extends STORAGE_KEYS>(key: T, data: StorageData[T]): void {
    this.storage.setItem(key, JSON.stringify(data));
  }

  remove(key: STORAGE_KEYS): void {
    this.storage.removeItem(key);
  }
}

const storageService = new StorageService(localStorage);

export const localStorageService = storageService;
