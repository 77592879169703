import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// Importing translation files
import translationCommonEN from './locales/en/common.json';
import translationMenuEN from './locales/en/menu.json';
import translationFooterEN from './locales/en/footer.json';
import translationLoginSignupEN from 'locales/en/loginAndSignup.json';
import translationProfileEN from 'locales/en/profile.json';
import translationProfileRU from 'locales/ru/profile.json';
import translationBonusEN from 'locales/en/bonus.json';
import translationCommonRU from './locales/ru/common.json';
import translationMenuRU from './locales/ru/menu.json';
import translationFooterRU from './locales/ru/footer.json';
import translationLoginSignupRU from 'locales/ru/loginAndSignup.json';
import translationBonusRU from 'locales/ru/bonus.json';
import navBarProfileEN from 'locales/en/navBarProfile.json';
import navBarProfileRU from 'locales/ru/navBarProfile.json';
import depositEN from 'locales/en/deposit.json';
import depositRU from 'locales/ru/deposit.json';
import validationEN from 'locales/en/validations.json';
import validationRU from 'locales/ru/validations.json';
import statisticsEN from 'locales/en/statistics.json';
import statisticsRU from 'locales/ru/statistics.json';
import notificationEN from 'locales/en/notification.json';
import notificationRU from 'locales/ru/notification.json';
import paymentEN from 'locales/en/payment.json';
import paymentRU from 'locales/ru/payment.json';
import levelsEN from 'locales/en/levels.json';
import levelsRU from 'locales/ru/levels.json';
import { STORAGE_KEYS, localStorageService } from 'utils/storageService';

//Creating object with the variables of imported translation files
const resources = {
  en: {
    menu: translationMenuEN,
    common: translationCommonEN,
    footer: translationFooterEN,
    loginAndSignup: translationLoginSignupEN,
    bonus: translationBonusEN,
    navBarProfile: navBarProfileEN,
    validation: validationEN,
    deposit: depositEN,
    profile: translationProfileEN,
    notification: notificationEN,
    statistics: statisticsEN,
    levels: levelsEN,
    payment: paymentEN
  },
  ru: {
    menu: translationMenuRU,
    common: translationCommonRU,
    footer: translationFooterRU,
    loginAndSignup: translationLoginSignupRU,
    bonus: translationBonusRU,
    navBarProfile: navBarProfileRU,
    validation: validationRU,
    deposit: depositRU,
    profile: translationProfileRU,
    notification: notificationRU,
    statistics: statisticsRU,
    levels: levelsRU,
    payment: paymentRU
  },
};

//i18N Initialization

i18n.use(initReactI18next).init({
  resources,
  lng: localStorageService.read(STORAGE_KEYS.SITE_LANGUAGE)
    ? localStorageService.read(STORAGE_KEYS.SITE_LANGUAGE)
    : 'ru', //default language
  fallbackLng: 'ru', // use en if detected lng is not available
  keySeparator: true,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
